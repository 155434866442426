import "./header.css";
import { Link } from "react-router-dom";

import tel from "../../image/tel.png";
import horas from "../../image/horas.png";
import whatsapp from "../../image/whatsapp_logo.png";

function Header() {
  return (
    <>
      <div className="pre-header">
        <div className="atendimento_header">
          <img src={horas} alt="imagem 24 horas"></img>
          <p className="atendimento-24horas">Atendimento 24 horas</p>
        </div>
        <div className="whatsapp_header">
          <img src={whatsapp} alt="whatsapp imagem"></img>
          <p className="numero_whatsapp">(71) 9 9324-4371</p>
        </div>
        <div className="fixo_header">
          <img src={tel} alt="telefone imagem"></img>
          <p className="numero_fixo">(71) 4104-2007</p>
        </div>
      </div>
      <header>
        <div className="links_header">
          <div className="navbar_logo">
          <Link className="logo" to="/">
            Villas
          </Link>
          </div>
          <div className="navbar_links">
          <Link className="header_contato" to="/contato">
            Contato
          </Link>
          <Link className="header_sobre" to="/sobre">
            Sobre nós
          </Link>

          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
