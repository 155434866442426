import "./contato.css";

import excelencia from "../../image/excelencia.png";
import horas from "../../image/horascontato.png";
import whats from "../../image/whats.png";
import mapa from "../../image/mapa.png";

function Contato() {
  return (
    <>
      <section className="window_contato">
        <h2>Contato</h2>
        <p className="window_text">
          Entre em contato conosco para esclarecer duvidas ou contratar nossos
          serviços
        </p>
      </section>
      <section className="container_contatos">
        <div className="container_horario">
          <div className="horario_funcionamento">
            <img src={horas} alt="horário de funcionamento 24 horas"></img>
            <h4>Atendimento 24 horas</h4>
            <p className="funcionamento">
              Funcionamos 24 horas, prontos para te atender com humanidade e
              respeito
            </p>
          </div>
          <div className="custo_beneficio">
            <img src={excelencia} alt="horário de funcionamento 24 horas"></img>
            <h4>Qualidade e custo-benefício</h4>
            <p className="funcionamento">
              Serviço prestado com excelência e com um ótimo custo benefício
            </p>
          </div>
        </div>
        <div className="container_agora">
          <p className="contato_agora">
            Entre em contato <strong>via WhatsApp </strong> e receba atendimento
            especializado <strong> sem custos. </strong>
          </p>

          <a
            className="botao_whatsapp_agora"
            href="https://contate.me/funerariavillas"
          >
            <img src={whats} alt="whatsapp "></img>
            Fale conosco agora
          </a>
        </div>
        <div className="container_localizacao">
          <div className="endereco_texto">
            <div className="titulo_endereco">
            <img src={mapa} alt="icone mapa"></img>
            <h3>Endereço</h3>
            </div>
        
            <h4>Ladeira da cruz da redenção, 513, Candeal, Salvador/BA</h4>
            <p className="abaixo">veja no mapa abaixo</p>
          </div>
          <div className="mapa">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6776171775714!2d-38.476866!3d-12.9924597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7161b48def39347%3A0xad78f73175acf8d9!2sLadeira%20da%20Cruz%20da%20Reden%C3%A7%C3%A3o%2C%20513%20-%20Itaigara%2C%20Salvador%20-%20BA%2C%2040296-190!5e0!3m2!1spt-BR!2sbr!4v1675183551192!5m2!1spt-BR!2sbr"
              width="80%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="container_telefones">
          <h4>Telefone</h4>
          <div className="telefones_cards">
          <div className="telefone_card">
              <p>(71) 4104-2007</p>
            </div>
          </div>
          <h4>Whatsapp</h4>
          <div className="whatsapp_cards">
            <div className="whatsapp_card">
              <p>(71) 9 9324-4371</p>
            </div>
            <div className="whatsapp_card">
              <p>(71) 9 8874-7337</p>
            </div>
            <div className="whatsapp_card">
              <p>(71) 9 9946-0412</p>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default Contato;
