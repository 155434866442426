import { BrowserRouter, Routes, Route} from 'react-router-dom';

import Home from './pages/Home'
import Contato from './pages/Contato';
import Sobre from './pages/Sobre';

import Header from './components/Header';
import Footer from './components/Footer/footer';

function RoutesApp(){
    return(
        <BrowserRouter>
        <Header/>
        <Routes>
            <Route path='/' element={ <Home/> } />
            <Route path='/sobre' element={ <Sobre/> } />
            <Route path='/contato' element={ <Contato/> } />
        </Routes>
        <Footer />
        </BrowserRouter>
    )

}

export default RoutesApp;