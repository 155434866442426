import "./sobre.css";


function Sobre() {
  return (
    <body>
      <div className="window_sobre">
        <h2>Quem somos?</h2>
        <h3>Informações a respeito da funerária Villas</h3>
      </div>
      <div className="container_central_sobre">
        <h3>Sobre nós</h3>
        <p className="sobre_nos">
          A <strong>funerária Villas</strong> bem no coração da cidade,
          localizada na Ladeira da Cruz da Redenção no Candeal.
          <br />
          <br /> Contamos com profissionais do ramo com mais de{" "}
          <strong>30 anos de experiência no segmento funerário</strong>,
          profissionais esses que são altamente capacitados para lhe atender da
          melhor forma possivel em um dos momentos mais dificeis da vida.
          <br />
          <br /> <strong>Trabalhamos 24 horas</strong> oferecendo a todos os
          familiares um tratamento digno, humanizado e respeitoso neste momento
          tão delicado que é o luto, conte com a gente, prestaremos todo o
          suporte necessário a você.
        </p>
      </div>
      <div className="contato_sobre">
        <h2>ENTRE EM CONTATO</h2>
        <h3>
          Dispomos de uma central de atendimento 24 horas, pronta para
          esclarecer quaisquer dúvidas que você possa ter sobre nossos serviços.
        </h3>
        <a
              className="fale_conosco_sobre"
              href="https://contate.me/funerariavillas"
              target="_blank"
            >
              <button>Fale Conosco agora</button>
            </a>
      </div>
    </body>
  );
}

export default Sobre;
