import "./home.css";
import { Link } from "react-router-dom";

//Atendimento
import telefone from "../../image/telefone.png";
import whats from "../../image/whats_app.png";
import atendimento from "../../image/atendimento.png";
import carro from "../../image/carro.png";
import documento from "../../image/documento.png";
//Sobre
import grandpa from "../../image/grandpa.jpg";

//Servicos
import flores from "../../image/flores.jpg";
import translado from "../../image/translado.jpg";
import juridico from "../../image/juridico.jpeg";
import cemiterio from "../../image/cemiterio.jpg";
import pagamento from "../../image/pagamento.jpg";
import caab from "../../image/caab.png";
import saeb from "../../image/saeb.png";
import jardimicon from "../../image/jardimicon.png";

function Home() {
  return (
    <>
      <body className="body_home">
        <div className="window">
          <h1 className="titulo">Funerária Villas</h1>
          <h2 className="subtitulo">No coração da cidade</h2>
          <div className="home_text">
            <article>
              {" "}
              A Funerária Villas é uma empresa sólida no segmento funerário,
              atuando com ética e profissionalismo em um momento tão delicado
              como o luto.
            </article>
          </div>
          <div className="links">
            <a
              className="fale_conosco"
              href="https://contate.me/funerariavillas"
              target="_blank"
            >
              <button>Fale Conosco</button>
            </a>
            <a
              className="localizacao"
              href="https://goo.gl/maps/s8wbCcg19XwPBrev7"
              target="_blank"
            >
              <button>Localização</button>
            </a>
          </div>
        </div>
        <div className="main_container">
          <div className="container_central">
            <h3 className="servicos">Atendimento 24 horas</h3>
            <article className="article_servicos">
              {" "}
              Funerária{" "}
              <strong>
                localizada na ladeira da Cruz da Redenção no numero 513 em
                Brotas,
              </strong>{" "}
              com funcionários experientes no segmento funerário, prestando
              serviços <strong>funerários com qualidade e organização.</strong>
            </article>
            <section className="section_cards">
              <div className="card">
                <img
                  src={telefone}
                  alt="Funcionamneto e atendimento 24 horas"
                ></img>
                <p>Funcionamento e atendimento 24 horas</p>
              </div>
              <div className="card">
                <img src={atendimento} alt="atendimento Personalizado"></img>
                <p>
                  Atendimento <br></br> Personalizado
                </p>
              </div>
              <div className="card">
                <img src={whats} alt="Contato por whatsapp"></img>
                <p>
                  Atendimento <br></br> por whatsapp
                </p>
              </div>
              <div className="card">
                <img src={documento} alt="Providência de documentação"></img>
                <p>Providência de documentação</p>
              </div>
              <div className="card">
                <img src={carro} alt="Deslocamento a residência"></img>
                <p>Deslocamento a residência e hospitais</p>
              </div>
            </section>
          </div>
        </div>
        <div className="container_feedbacks">
          <h3 className="servicos_dep">Depoimentos de Clientes</h3>
          <div className="box_feedbacks">
            <div className="card_feedback">
              <p className="depoimento">
                " Muito obrigado pelo excelente serviço prestado q aliviou em
                muito a dor da nossa perda 🙏"
              </p>
              <p className="nome_depoimento">Eduardo via Whatsapp</p>
              <p className="ano_depoimento">2023</p>
            </div>
            <div className="card_feedback">
              <p className="depoimento">
                " Passando para agradecer imensamente toda disponibilidade e
                paciência conosco em todo o processo. Isso fez muita
                diferença!🥰 "
              </p>
              <p className="nome_depoimento">Cliente via Whatsapp</p>
              <p className="ano_depoimento">2023</p>
            </div>
            <div className="card_feedback">
              <p className="depoimento">
               " Gostaria de agradecer a você e toda equipe da Funerária
                Villas pelo acolhimento e excelente atendimento prestado ao
                funeral de minha sogra "
              </p>
              <p className="nome_depoimento">Cliente via Whatsapp</p>
              <p className="ano_depoimento">2023</p>
            </div>
    
          </div>
        </div>
        <div className="quem_somos_home">
          <div className="container_imagem_home">
            <img
              className="grandpa"
              src={grandpa}
              alt="idosos se abraçando"
            ></img>
          </div>
          <div className="container_texto_home">
            <h3 className="titulo_texto">Quem somos ?</h3>
            <p className="texto_home">
              Somos uma empresa fundada por profissionais que atuaram juntos ao
              <strong className="jardim_saudade"> Jardim da Saudade</strong> ao
              longo desses últimos 30 anos. A nossa missão é atender e dar o
              acolhimento necessário, auxiliando nesse momento de luto a toda a
              família enlutada, junto a todos os cemitérios da cidade.
              <br />
              <br />
              <strong>Trabalhamos 24 horas </strong>oferecendo a todos os
              familiares um tratamento digno, humanizado e respeitoso neste
              momento tão delicado que é o luto, conte com a gente, prestaremos
              todo o suporte necessário a você.
            </p>
            <div className="container_button_sobre">
              <a href="/sobre" className="button_sobre_home">
                <button className="button_home">Veja mais</button>
              </a>
            </div>
          </div>
        </div>
        <div className="pre_servico">
          <h2 className="pre_titulo_servico">Nossos Serviços</h2>
          <p className="texto_pre_servico">
            A Funerária Villas oferece serviços funerários com pessoal
            capacitado para a gestão completa da tarefa de remoção, traslado,
            acessoria jurídica entre outras coisas.
          </p>
        </div>
        <div className="servicos_home">
          <div className="container_servicos">
            <div className="container_card">
              <img
                src={flores}
                alt="flores"
                tittle="Ornamentação e Coroas de Flores"
              ></img>
              <div className="text_background">
                <article className="container_text">
                  Ornamentação e Coroa de Flores
                </article>
              </div>
            </div>
            <div className="container_card">
              <img
                src={translado}
                alt="translado"
                tittle="Translado e remoção"
              ></img>
              <div className="text_background">
                <article className="container_text">Translado</article>
              </div>
            </div>
            <div className="container_card">
              <img
                src={juridico}
                alt="juridico"
                tittle="Acessoria Juridica"
              ></img>
              <div className="text_background">
                <article className="container_text">Urnas Mortuárias</article>
              </div>
            </div>
            <div className="container_card">
              <img
                src={cemiterio}
                alt="Agendamento e Reservas"
                tittle="Agendamentos e reservas junto ao cemitério"
              ></img>
              <div className="text_background">
                <article className="container_text">
                  Agendamentos e reservas
                </article>
              </div>
            </div>
            <div className="container_card">
              <img
                src={pagamento}
                alt="Pagamento flexivel"
                tittle="Contamos com pagamento flexivel"
              ></img>
              <div className="text_background">
                <article className="container_text">Pagamento Flexivel</article>
              </div>
            </div>
          </div>
        </div>

        <div className="convenios">
          <div className="convenios_titulo">
            <h3>Convênios</h3>
          </div>

          <div className="convenios_box">
            <a href="http://www.saeb.ba.gov.br" target="_blank">
              <div className="saeb">
                <img
                  src={saeb}
                  alt="Logo SAEB"
                  tittle="Logo da SAEB"
                  className="saeb-logo"
                ></img>
              </div>
            </a>
            <a href="https://caab.org.br" target="_blank">
              <div className="caab">
                <img
                  src={caab}
                  alt="Logo caab"
                  tittle="Logo da caab"
                  className="caab-logo"
                ></img>
              </div>
            </a>
            <a
              href="https://jardimdasaudadebahia.com.br/convenios/"
              target="_blank"
            >
              <div className="convenio_jardim">
                <img
                  src={jardimicon}
                  alt="Logo Jardim da Saudade"
                  tittle="Logo da Jardim da Saudade"
                  className="jardimicon-imagem"
                ></img>
              </div>
            </a>
          </div>
        </div>
        <div className="contato_sobre_home">
          <h2>ENTRE EM CONTATO</h2>
          <h3>
            Dispomos de uma central de atendimento 24 horas, pronta para
            esclarecer quaisquer dúvidas que você possa ter sobre nossos
            serviços.
          </h3>
          <a
            className="fale_conosco_home"
            href="https://contate.me/funerariavillas"
            target="_blank"
          >
            <button>Fale Conosco agora</button>
          </a>
        </div>
      </body>
    </>
  );
}

export default Home;
